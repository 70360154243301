/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getLotReducer } from "../../store/lot/lot.selector";
import { getContentReducer } from "../../store/content/content.selector";
import BackButton from "../../components/BackButton/BackButton.component";
import Loader from "../../components/Loader/Loader.component";
import Empty from "../../components/Empty/Empty.component";
import Element from "../../components/Element/Element.component";

const Pieces = () => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { pathname } = useLocation();
  const { projectID, scaleID, lotID } = useParams();
  const { loading, pieces, piecesName } = useSelector(getLotReducer);
  const {
    texts: { videosEmpty },
  } = useSelector(getContentReducer);

  /* url de retour */
  const returnPath = pathname.split("/").includes("plan")
    ? `/project/${projectID}/plan/${lotID}`
    : `/project/${projectID}/scale/${scaleID}/search/results/${lotID}`;

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton text={piecesName} link={returnPath} />
      <div>
        {loading ? (
          <div className="relative h-56">
            <Loader />
          </div>
        ) : (
          <>
            {pieces ? (
              <ul>
                {pieces.map((piece) => (
                  <li key={piece.id}>
                    <Element text={piece.name} link={`${piece.id}${window?.location?.search}`} />
                  </li>
                ))}
              </ul>
            ) : (
              <li>
                <Empty text={videosEmpty} />
              </li>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Pieces;
