/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSocketsLogoURL } from "../../utils/websockets.utils";
import { getProjects, getVideos } from "../../store/projects/projects.selector";
import { getContentReducer } from "../../store/content/content.selector";
import { setLastPage } from "../../store/history/history.reducer";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { useNavigate } from "react-router-dom";
import Element from "../../components/Element/Element.component";
import Loader from "../../components/Loader/Loader.component";
import Title from "../../components/Title/Title.component";
import Empty from "../../components/Empty/Empty.component";
import { getProjectsRoutes } from "../../utils/helpers.utils";

const Projects = ({ loading, socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projects = useSelector(getProjects);
  const videos = useSelector(getVideos);
  const {
    texts: { textProjects, projectsEmpty },
  } = useSelector(getContentReducer);
  const { singleProject, logoUrl, socketID } = useSelector(getAuthReducer);
  const projectsRoutes = useMemo(
    () => getProjectsRoutes(projects, videos),
    [projects, videos]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    dispatch(setLastPage("projects"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* redirection si un seul projet */
  useEffect(() => {
    if (singleProject && projectsRoutes.length === 1)
      navigate(`/project/${projectsRoutes[0].id}${window?.location?.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsRoutes, singleProject]);

  /* récupération de l'avatar */
  useEffect(() => {
    if (logoUrl && socket && socketID) {
      setSocketsLogoURL(process.env.REACT_APP_STRAPI + logoUrl, socketID);
    }
  }, [logoUrl, socket, socketID]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      {loading ? (
        <div className="relative h-56">
          <Loader />
        </div>
      ) : projects && projects.length ? (
        <>
          <div className="sticky top-0 flex justify-center items-center py-6 bg-white z-50">
            <Title text={textProjects} />
          </div>
          <ul>
            {projects.map((project) => (
              <li key={project.id}>
                <Element
                  text={project.name}
                  link={`/project/${project.id}${window?.location?.search}`}
                  icon={project.logo}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <div className="sticky top-0 flex justify-center items-center py-6 bg-white z-50">
            <Title text={textProjects} />
          </div>
          <Empty text={projectsEmpty} />
        </>
      )}
    </>
  );
};

export default Projects;
