/*//////////////////////*/
/* Imports
/*/ /////////////////////*/

import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getLotReducer } from "../../store/lot/lot.selector";
import { getContentReducer } from "../../store/content/content.selector";
import { useMemo } from "react";
import BackButton from "../../components/BackButton/BackButton.component";
import Loader from "../../components/Loader/Loader.component";
import VideoPrestation from "../../components/Video/Prestation/VideoPrestation.component";
import Empty from "../../components/Empty/Empty.component";

const Prestation = ({ socket }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { pathname } = useLocation();
  const { projectID, scaleID, lotID, pieceID, prestationID } = useParams();
  const { loading, pieces } = useSelector(getLotReducer);
  const {
    texts: { videosEmpty },
  } = useSelector(getContentReducer);

  /* url de retour */
  const returnPath = pathname.split("/").includes("plan")
    ? `/project/${projectID}/plan/${lotID}/pieces/${pieceID}${window?.location?.search}`
    : `/project/${projectID}/scale/${scaleID}/search/results/${lotID}/pieces/${pieceID}${window?.location?.search}`;

  const prestation = useMemo(() => {
    const piece = pieces.find((piece) => piece.id === +pieceID);
    return piece?.prestations.find(
      (prestation) => prestation.id === +prestationID
    );
  }, [pieces, pieceID, prestationID]);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <>
      <BackButton
        text={prestation?.name ? prestation.name : ""}
        link={returnPath}
      />
      <div>
        {loading ? (
          <div className="relative h-56">
            <Loader />
          </div>
        ) : (
          <>
            {prestation?.file ? (
              <VideoPrestation informations={prestation} socket={socket} />
            ) : (
              <Empty text={videosEmpty} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Prestation;
