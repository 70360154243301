import * as qs from "qs";

/**
 * Retrouver utilisateur Strapi au login
 * @param {string} email
 * @param {string} password
 * @returns
 */
export const getStrapiUser = async (email, password) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identifier: email.replace(/\s+/g, ""),
      password: password,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/auth/local`,
    options
  );
  const data = await response.json();
  return data;
};

/**
 * Retrouver les projets reliés à un client
 * @param {integer} userID
 * @param {string} userToken
 * @returns
 */
export const getStrapiProjects = async (userID, userToken) => {

  const options = {
    method: "GET",
    headers: { Authorization: `Bearer ${userToken}` },
    redirect: "follow",
  };

  const query = qs.stringify(
    {
      sort: ["rank:asc"],
      fields: [
        "name",
        "colorTexts",
        "colorPrimary",
        "programme",
        "plan",
        "planName",
        "isRotative",
        "idleTimeout"
      ],
      filters: {
        users: {
          id: {
            $eq: userID,
          },
        },
      },
      populate: {
        logo: {
          fields: ["url"],
        },
        scales: {
          fields: [
            "rank",
            "name",
            "search",
            "searchDetails",
            "searchPrintOnly",
            "useLogoForBackground",
            "searchBackgroundImage",
            "searchBatiment",
            "isReadyVideo",
            "AngleForRotativeProject"
          ],
          populate: {
            icon: {
              fields: ["url"],
            },
            lots: {
              fields: ["name", "slug"],
            },
          },
        },
        planIcon: {
          fields: ["url"],
        },
        planLots: {
          fields: ["name", "slug"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/projects?${query}`,
    options
  );
  const data = await response.json();

  return data;
};

/**
 * Retrouver les vidéos reliés à des projets
 * @param {array} projectsIDs
 * @param {string} userToken
 * @returns
 */
export const getStrapiVideos = async (
  projectsIDs,
  userToken,
  isAdmin = false
) => {
  const options = {
    method: "GET",
    headers: { Authorization: `Bearer ${userToken}` },
    redirect: "follow",
  };

  const filters = isAdmin
    ? {
        projet: {
          id: {
            $in: projectsIDs,
          },
        },
      }
    : {
        projet: {
          id: {
            $in: projectsIDs,
          },
        },
        $and: [{ admin: { $ne: true } }],
      };

  const query = qs.stringify(
    {
      sort: ["rank:asc"],
      fields: [
        "name",
        "type",
        "file",
        "rotaryStart",
        "rotaryNorth",
        "rotaryEast",
        "rotarySouth",
        "rotaryWest",
        "sliderHours",
        "sliderStart",
        "sliderEnd",
        "auto",
        "onlyAuto",
        "videoDuration",
        "accept3D",
        "disableAuto",
        "hidePlay",
      ],
      filters,
      populate: {
        chapters: {
          fields: ["name", "seconds", "end"],
        },
        projet: {
          fields: ["id"],
        },
        scale: {
          fields: ["id"],
        },
        chaptersFileContent: {
          fields: ["url"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/videos?${query}`,
    options
  );
  const data = await response.json();
  return data;
};

/**
 * Retrouver les vidéos reliés à un lot
 * @param {array} projectsIDs
 * @param {string} userToken
 * @returns
 */
export const getLotContent = async (lotID, projectID, userToken) => {
  const options = {
    method: "GET",
    headers: { Authorization: `Bearer ${userToken}` },
    redirect: "follow",
  };

  const query = qs.stringify(
    {
      sort: ["rank:asc"],
      fields: [
        "name",
        "slug",
        "rotaryStart",
        "rotaryNorth",
        "rotaryEast",
        "rotarySouth",
        "rotaryWest",
        "piecesName",
      ],
      filters: {
        slug: {
          $eq: lotID,
        },
        $and: [
          {
            projetsLots: {
              id: {
                $eq: projectID,
              },
            },
          },
        ],
      },
      populate: {
        videos: {
          fields: ["id", "name", "videoDuration"],
        },
        pieces: {
          fields: ["name", "file", "accept3D"],
          populate: {
            prestations: {
              fields: ["name", "file", "accept3D"],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/lots?${query}`,
    options
  );
  const data = await response.json();

  return data;
};

/**
 * Retrouver les textes génériques
 * @returns
 */
export const getStrapiContent = async () => {
  const options = {
    method: "GET",
    redirect: "follow",
  };

  // recuperation de la langue 
  let lang = null
  const search = window?.location?.search
  if (search && search.includes("locale")) {
    lang = search
  }

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/general${lang ? lang : ""}`,
    options
  );
  const data = await response.json();
  return data;
};

/**
 * Retrouver l'utilisateur actuel
 * @param {string} userToken
 * @returns
 */
export const getCurrentUser = async (userToken, userID) => {
  const options = {
    method: "GET",
    headers: { Authorization: `Bearer ${userToken}` },
    redirect: "follow",
  };

  const response = await fetch(
    `${process.env.REACT_APP_STRAPI}/api/users/${userID}?populate=logo,logoApp`,
    options
  );

  const data = await response.json();
  return data;
};
