/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAuthReducer } from "../../store/auth/auth.selector";
import { resetColors, setColors } from "../../utils/colors.utils";
import { getImageURL, getProjectColors } from "../../utils/helpers.utils";
import {
  getProjectLogoURL,
  getProjectsReducer,
} from "../../store/projects/projects.selector";
import { HomeIcon } from "../../icons/Home.icon";

import "./Navigation.styles.scss";

const Navigation = ({ isHome }) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const { projectID } = useParams();
  const { avatar, username, isAdmin, singleProject } =
    useSelector(getAuthReducer);
  const projectsReducer = useSelector(getProjectsReducer);
  const selectorProjectLogo = useSelector(getProjectLogoURL(projectID));
  const [projectLogo, setProjectLogo] = useState(null);
  const [projectColors, setProjectColors] = useState(null);
  const selectorProjectColors = useMemo(
    () => getProjectColors(projectsReducer, projectID),
    [projectsReducer, projectID]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    setProjectLogo(selectorProjectLogo);
    setProjectColors(selectorProjectColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  useEffect(() => {
    if (projectColors) {
      setColors(projectColors);
    } else {
      if (!singleProject) resetColors();
    }
  }, [projectColors, singleProject]);

  useEffect(() => {
    if (isHome) setProjectColors(null);
  }, [isHome]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="navigation">
      <div className={`flex px-8 py-4 justify-between`}>
        <>
          {!isHome ? (
            <Link to={`/${window?.location?.search}`}>
              <HomeIcon />
            </Link>
          ) : (
            <hr className="block w-8 border-0" />
          )}
        </>

        {projectLogo ? (
          <>
            {isAdmin ? (
              <Link to={`available${window?.location?.search}`}>
                <img src={getImageURL(projectLogo)} alt={`logo ${username}`} />
              </Link>
            ) : (
              <img src={getImageURL(projectLogo)} alt={`logo ${username}`} />
            )}
          </>
        ) : (
          avatar && (
            <>
              {isAdmin ? (
                <Link to={`available${window?.location?.search}`}>
                  <img src={getImageURL(avatar)} alt={`logo ${username}`} />
                </Link>
              ) : (
                <img src={getImageURL(avatar)} alt={`logo ${username}`} />
              )}
            </>
          )
        )}


        <hr className="block w-8 border-0" />
      </div>
    </div>
  );
};

export default Navigation;
