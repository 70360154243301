/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContentReducer } from "../../store/content/content.selector";
import { setFromPage } from "../../store/lot/lot.reducer";
import { CheckedIcon } from "../../icons/Checked.icon";
import { ArrowRight } from "../../icons/ArrowRight.icon";
import { SurfaceIcon } from "../../icons/Surface.icon";
import { BatimentIcon } from "../../icons/Batiment.icon";
import { EtageIcon } from "../../icons/Etage.icon";
import { OptionsIcon } from "../../icons/Options.icon";
import { OrientationIcon } from "../../icons/Orientation.icon";
import { TypeIcon } from "../../icons/Type.icon";
import { PrixIcon } from "../../icons/Prix.icon";
import "./Lot.styles.scss";

const Lot = ({
  lot,
  ecranLot,
  allChecked,
  updateEcranLot,
  updateSelectedLots,
  programmeDatas,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const checked = useRef(true);
  const [name, setName] = useState(null);
  const [url, setURL] = useState(null);
  const {
    texts: {
      searchLotButton,
      searchLotButtonLocalize,
      searchLotButtonUnlocalize,
    },
  } = useSelector(getContentReducer);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    checked.current = allChecked;
  }, [allChecked]);

  useEffect(() => {
    if (programmeDatas && programmeDatas?.name) setName(programmeDatas.name);
    if (programmeDatas && programmeDatas?.url) setURL(programmeDatas.url);
  }, [programmeDatas]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  const lotClick = () => {
    checked.current = !checked.current;
    if (lot && lot?.name) {
      updateSelectedLots(lot.name, checked.current);
    }
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <article
      className={`lot lot--notfull ${
        ecranLot === lot.name && "lot--selected"
      } h-full`}
    >
      <div
        className="lot__heading | text-center cursor-pointer"
        onClick={lotClick}
      >
        <div
          className={`relative flex items-center ${
            lot.statut === "Disponible" ? "justify-between" : "justify-start"
          }`}
        >
          <strong className="text-white z-10 uppercase bold text-lg">
            {lot.name}
          </strong>
          {lot.statut === "Disponible" && (
            <span className="lot__dispo opacity-80 text-sm">{lot.statut}</span>
          )}
        </div>
      </div>

      <div
        className={`lot__body | flex content-between ${
          !lot.printed && name && url ? "pt-4 px-4" : "p-4"
        }`}
      >
        <div className="flex items-center cursor-pointer" onClick={lotClick}>
          <CheckedIcon checked={checked?.current} />
          <input
            type="checkbox"
            checked={checked?.current}
            onChange={() => {}}
          />
          <hr />
          <div>
            <div className="flex flex-wrap">
              {lot.prix > 0 && (
                <div className="lot__details">
                  <PrixIcon />
                  <span>{lot.prix}€</span>
                </div>
              )}

              {lot.surface > 0 && (
                <div className="lot__details">
                  <SurfaceIcon />
                  <span>{lot.surface}m²</span>
                </div>
              )}

              {lot.numPieces > 0 && (
                <div className="lot__details">
                  <TypeIcon />
                  <span>T{lot.numPieces}</span>
                </div>
              )}

              {lot.etage !== "" && (
                <div className="lot__details">
                  <EtageIcon />
                  <span>{lot.etage}</span>
                </div>
              )}

              {lot.batiment !== "" && (
                <div className="lot__details">
                  <BatimentIcon />
                  <span>{lot.batiment}</span>
                </div>
              )}

              {lot.orientation !== "" &&
                lot.orientation !== "Non-spécifiée" && (
                  <div className="lot__details">
                    <OrientationIcon />
                    <span>{lot.orientation}</span>
                  </div>
                )}

              {lot.options && (
                <div className="lot__details">
                  <OptionsIcon />
                  <span>{lot.options.toString()}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <footer className="w-full button-parent mt-4 z-20">
          <div>
            <button
              className="button button--secondary relative"
              onClick={() =>
                updateEcranLot(lot?.name, ecranLot !== lot.name ? true : false)
              }
            >
              {ecranLot !== lot.name
                ? searchLotButtonLocalize
                : searchLotButtonUnlocalize}
            </button>
          </div>

          {!lot.printed && name && url && (
            <div className="flex justify-end">
              <a
                href={`https://${url}.3d.virtualbuilding.fr/${name}/detail-lot/${lot.name}/plan`}
                target="_blank"
                rel="noreferrer"
                className="arrow py-4 pl-4"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ArrowRight className="icon" />
              </a>
            </div>
          )}

          {lot.printed && (
            <div
              className="mt-4"
              onClick={() => {
                dispatch(setFromPage(pathname + search));
              }}
            >
              <Link className="button text-center" to={`${lot.name}${window?.location?.search}`}>
                {searchLotButton}
              </Link>
            </div>
          )}
        </footer>
      </div>
    </article>
  );
};

export default Lot;
