export const getFormatProjects = (projectsData) => {
  const formatedProjects = projectsData.map((project) => {
    const {
      attributes: {
        name,
        logo: {
          data: {
            attributes: { url },
          },
        },
        scales,
        colorTexts,
        colorPrimary,
        programme,
        plan,
        planName,
        planIcon,
        planLots,
        isRotative,
        idleTimeout
      },
      id,
    } = project;
    const projectScales = scales.data.map((scale) => {
      const {
        attributes: {
          rank,
          name,
          icon: {
            data: {
              attributes: { url },
            },
          },
          search,
          searchDetails,
          searchPrintOnly,
          lots,
          useLogoForBackground,
          searchBackgroundImage,
          searchBatiment,
          isReadyVideo,
          AngleForRotativeProject
        },
        id,
      } = scale;
      return {
        id,
        rank,
        name,
        icon: url,
        search,
        searchDetails,
        searchPrintOnly,
        lots,
        useLogoForBackground,
        searchBackgroundImage,
        searchBatiment,
        isReadyVideo,
        AngleForRotativeProject
      };
    });
    return {
      id,
      name,
      logo: url,
      scales: projectScales,
      colorTexts,
      colorPrimary,
      programme,
      plan,
      planName,
      planIcon:
        planIcon?.data && planIcon.data !== null
          ? planIcon.data.attributes.url
          : null,
      planLots: planLots?.data && planLots.data !== null ? planLots.data : null,
      isRotative : isRotative ? true : false,
      idleTimeout : idleTimeout
    };
  });

  return formatedProjects;
};

export const getFormatVideos = (videosData) => {
  const formatedVideos = videosData.map((video) => {
    const {
      attributes: {
        file,
        name,
        type,
        chapters,
        projet: { data: projetData },
        scale: { data: scaleData },
        chaptersFileContent: { data: chaptersFileContent },
        rotaryStart,
        rotaryNorth,
        rotaryEast,
        rotarySouth,
        rotaryWest,
        sliderHours,
        sliderStart,
        sliderEnd,
        auto,
        onlyAuto,
        videoDuration,
        accept3D,
        disableAuto,
        hidePlay,
      },
      id,
    } = video;

    const projectID = projetData && projetData?.id ? projetData.id : null;
    const scaleID = scaleData && scaleData?.id ? scaleData.id : null;
    const chaptersFileURL =
      chaptersFileContent && chaptersFileContent?.attributes
        ? chaptersFileContent.attributes.url
        : null;

    return {
      id,
      name,
      file,
      type,
      chapters,
      chaptersFileURL,
      projectID,
      scaleID,
      rotaryStart,
      rotaryNorth,
      rotaryEast,
      rotarySouth,
      rotaryWest,
      sliderHours,
      sliderStart,
      sliderEnd,
      auto,
      onlyAuto,
      videoDuration,
      accept3D,
      disableAuto,
      hidePlay,
    };
  });

  return formatedVideos;
};
